var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form-wizard',{staticClass:"wizard-vertical mb-3",attrs:{"color":"#2d71b8","title":null,"subtitle":null,"layout":"vertical","finish-button-text":"Finish","back-button-text":"Previous"},on:{"on-complete":_vm.affiliateAdd}},[_c('tab-content',{attrs:{"title":"User Details","icon":"feather icon-user-plus","before-change":_vm.userAdd}},[_c('validation-observer',{ref:"userDetailsRules"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" User Details ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Enter User's Information. ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"fh-name"}},[_c('validation-provider',{attrs:{"rules":"required|alpha","name":"First Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserPlusIcon"}})],1),_c('b-form-input',{attrs:{"id":"fh-name","placeholder":"Karim"},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Last Name","label-for":"fh-lastname"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserMinusIcon"}})],1),_c('b-form-input',{attrs:{"id":"fh-lastname","placeholder":"Abdullah"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"fh-email"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"fh-email","type":"email","placeholder":"Enter a valid Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Username","label-for":"fh-username"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserCheckIcon"}})],1),_c('b-form-input',{attrs:{"id":"fh-username","placeholder":"Enter a valid username"},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"fh-password"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{attrs:{"id":"fh-password","type":"password","placeholder":"Password"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"fh-retype"}},[_c('validation-provider',{attrs:{"rules":'required|regex:'+_vm.user.password,"name":"Confirm Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{attrs:{"id":"fh-retype","type":"password","placeholder":"Re-type Password"},model:{value:(_vm.user.password2),callback:function ($$v) {_vm.$set(_vm.user, "password2", $$v)},expression:"user.password2"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Affiliate Details","icon":"feather icon-command"}},[(!_vm.finished)?_c('validation-observer',{ref:"AffiliateDetailsRules"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Affiliate Details ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Enter Your Affiliate Details. ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Organization Name","label-for":"fh-organizationname"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Organization Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"HexagonIcon"}})],1),_c('b-form-input',{attrs:{"id":"fh-organizationname","placeholder":"Onboard HUB"},model:{value:( _vm.affiliate_info.organization_name),callback:function ($$v) {_vm.$set(_vm.affiliate_info, "organization_name", $$v)},expression:" affiliate_info.organization_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3934445123)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"fh-type"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"fh-type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.type},model:{value:( _vm.affiliate_info.affiliate_type),callback:function ($$v) {_vm.$set(_vm.affiliate_info, "affiliate_type", $$v)},expression:" affiliate_info.affiliate_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2796182193)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Onboarding Commission","label-for":"fh-onboarding_commission"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Onboarding Commission"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"HexagonIcon"}})],1),_c('b-form-input',{attrs:{"id":"fh-onboarding_commission","placeholder":"2.5"},model:{value:( _vm.affiliate_info.onboarding_commission),callback:function ($$v) {_vm.$set(_vm.affiliate_info, "onboarding_commission", $$v)},expression:" affiliate_info.onboarding_commission"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2878686737)})],1)],1),(_vm.affiliate_info.affiliate_type!='AFFILIATE')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Retention Commission","label-for":"fh-retention_commission"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Retention Commission"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"HexagonIcon"}})],1),_c('b-form-input',{attrs:{"id":"fh-retention_commission","placeholder":"2.5"},model:{value:( _vm.affiliate_info.retention_commission),callback:function ($$v) {_vm.$set(_vm.affiliate_info, "retention_commission", $$v)},expression:" affiliate_info.retention_commission"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2777918417)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Payout Method","label-for":"fh-payout_method"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Payout Method"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"HexagonIcon"}})],1),_c('b-form-input',{attrs:{"id":"fh-payout_method","placeholder":"Bank/Bkash/Rocket"},model:{value:( _vm.affiliate_info.payout_method),callback:function ($$v) {_vm.$set(_vm.affiliate_info, "payout_method", $$v)},expression:" affiliate_info.payout_method"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3756311881)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Payout Details","label-for":"fh-payout_details"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Payout Details"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"HexagonIcon"}})],1),_c('b-form-input',{attrs:{"id":"fh-payout_details","placeholder":"Bank - City Bank Acc Details"},model:{value:( _vm.affiliate_info.payout_details),callback:function ($$v) {_vm.$set(_vm.affiliate_info, "payout_details", $$v)},expression:" affiliate_info.payout_details"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3383744017)})],1)],1)],1)],1):_c('div',[_c('b-row',{staticClass:"justify-content-center"},[_c('span',[_vm._v("You have "),_c('span',{staticClass:"text-success text-bold"},[_vm._v("SUCCESSFULLY")]),_vm._v(" onboarded an "+_vm._s(_vm.affiliate_info.affiliate_type)+".")])]),_c('b-row',{staticClass:"justify-content-center"},[_c('span',[_vm._v("To "),_c('span',{staticClass:"text-success text-bold"},[_vm._v(" Onboard another "+_vm._s(_vm.affiliate_info.affiliate_type))]),_vm._v(" click "),_c('span',{staticClass:"text-primary text-bold"},[_vm._v("Onboard Another ")]),_vm._v(" Button.")])]),_c('b-row',{staticClass:"justify-content-center mt-75"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addAnother}},[_vm._v("Onboard Another")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }