<template>
  <div>

    <form-wizard
        color="#2d71b8"
        :title="null"
        :subtitle="null"
        layout="vertical"
        finish-button-text="Finish"
        back-button-text="Previous"
        class="wizard-vertical mb-3"
        @on-complete="affiliateAdd"

    >
      <tab-content title="User Details" icon="feather icon-user-plus" :before-change="userAdd">
        <validation-observer ref="userDetailsRules" >
          <b-form>
            <b-row>
              <b-col
                  cols="12"
                  class="mb-2"
              >
                <h5 class="mb-0">
                  User Details
                </h5>
                <small class="text-muted">
                  Enter User's Information.
                </small>
              </b-col>
              <b-col md="6">

                <b-form-group
                    label="First Name"
                    label-for="fh-name"

                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required|alpha"
                      name="First Name"
                  >
                    <b-input-group
                        class="input-group-merge"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserPlusIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="fh-name"
                          placeholder="Karim"
                          v-model="user.first_name"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Last Name"
                    label-for="fh-lastname"
                >

                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserMinusIcon"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="fh-lastname"
                        placeholder="Abdullah"
                        v-model="user.last_name"
                    />
                  </b-input-group>

                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Email"
                    label-for="fh-email"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required|email"
                      name="Email"
                  >
                    <b-input-group class="input-group-merge">

                      <b-input-group-prepend is-text>
                        <feather-icon icon="MailIcon"/>
                      </b-input-group-prepend>

                      <b-form-input
                          id="fh-email"
                          type="email"
                          placeholder="Enter a valid Email"
                          v-model="user.email"
                      />


                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Username"
                    label-for="fh-username"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Username"
                  >
                    <b-input-group class="input-group-merge">

                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserCheckIcon"/>
                      </b-input-group-prepend>

                      <b-form-input
                          id="fh-username"

                          placeholder="Enter a valid username"
                          v-model="user.username"
                      />


                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                    label="Password"
                    label-for="fh-password"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Password"
                  >

                    <b-input-group
                        class="input-group-merge"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LockIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="fh-password"
                          v-model="user.password"
                          type="password"
                          placeholder="Password"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    label="Confirm Password"
                    label-for="fh-retype"
                >
                  <validation-provider
                      #default="{ errors }"
                      :rules="'required|regex:'+user.password"
                      name="Confirm Password"
                  >

                    <b-input-group
                        class="input-group-merge"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LockIcon"/>
                      </b-input-group-prepend>
                      <b-form-input
                          id="fh-retype"
                          v-model="user.password2"
                          type="password"
                          placeholder="Re-type Password"
                      />

                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>

      <!--       Business datails tab -->
      <tab-content title="Affiliate Details" icon="feather icon-command">
        <validation-observer ref="AffiliateDetailsRules"  v-if="!finished">
          <b-row>
            <b-col
                cols="12"
                class="mb-2"
            >
              <h5 class="mb-0">
                Affiliate Details
              </h5>
              <small class="text-muted">
                Enter Your Affiliate Details.
              </small>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Organization Name"
                  label-for="fh-organizationname"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Organization Name"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="HexagonIcon"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="fh-organizationname"
                        placeholder="Onboard HUB"
                        v-model=" affiliate_info.organization_name"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Type"
                  label-for="fh-type"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Type"
                >
                  <v-select
                      id="fh-type"
                      v-model=" affiliate_info.affiliate_type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="type"

                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Onboarding Commission"
                  label-for="fh-onboarding_commission"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Onboarding Commission"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="HexagonIcon"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="fh-onboarding_commission"
                        placeholder="2.5"
                        v-model=" affiliate_info.onboarding_commission"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="affiliate_info.affiliate_type!='AFFILIATE'" >
              <b-form-group
                  label="Retention Commission"
                  label-for="fh-retention_commission"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Retention Commission"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="HexagonIcon"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="fh-retention_commission"
                        placeholder="2.5"
                        v-model=" affiliate_info.retention_commission"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Payout Method"
                  label-for="fh-payout_method"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Payout Method"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="HexagonIcon"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="fh-payout_method"
                        placeholder="Bank/Bkash/Rocket"
                        v-model=" affiliate_info.payout_method"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                  label="Payout Details"
                  label-for="fh-payout_details"
              >
                <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Payout Details"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="HexagonIcon"/>
                    </b-input-group-prepend>
                    <b-form-input
                        id="fh-payout_details"
                        placeholder="Bank - City Bank Acc Details"
                        v-model=" affiliate_info.payout_details"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <div v-else>
          <b-row class="justify-content-center">
            <span>You have <span class="text-success text-bold">SUCCESSFULLY</span> onboarded an {{ affiliate_info.affiliate_type }}.</span>
          </b-row>
          <b-row  class="justify-content-center">
            <span>To <span class="text-success text-bold"> Onboard another {{ affiliate_info.affiliate_type }}</span> click <span
                class="text-primary text-bold">Onboard Another </span> Button.</span>
          </b-row>
          <b-row  class="justify-content-center mt-75">
            <b-button variant="primary" @click="addAnother">Onboard Another</b-button>
          </b-row>
        </div>
      </tab-content>


    </form-wizard>


  </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BFormFile,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BFormRating,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
  BImg, BContainer
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {mapState} from "vuex";

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BForm,
    BFormTextarea,
    BButton,
    BFormRating,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BImg, BContainer,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  watch: {},
  created() {

  },
  computed: {},
  data() {
    return {

      user: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        password2: "",
      },

      affiliate_info: {
        organization_name: "",
        organization_address: "",
        affiliate_type: "",
        user: "",
        onboarding_commission: 0.0,
        retention_commission: 0.0,
        payout_method: "",
        payout_details: "",
      },
      type:[
        'FRANCHISE',
        'AFFILIATE'
      ],

      confirm_password: "",

      userAdded: false,
      affiliateAdded: false,
      finished: false,

      user_id: null,


    }
  },
  methods: {


    addAnother() {
      this.$router.go();
    },
    userAdd() {
      if (this.userAdded) {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.userDetailsRules.validate().then(async success => {
            if (success) {
              await this.$store.dispatch('affiliate/addUser', this.user).then(result => {
                if (result.code != 200) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-end',
                    props: {
                      title: ' Error',
                      icon: 'AlertOctagonIcon',
                      variant: 'danger',
                      text: result.message,
                    },
                  })
                  reject()
                } else {

                  this.affiliate_info.user = result.user_info.id
                  this.user_id = result.user_info.id
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: result.code == 400 ? 'Error' : `Success`,
                      icon: result.code == 400 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',
                      variant: result.code == 400 ? 'danger' : 'success',
                      text: result.response,
                    },
                  })
                  this.userAdded = true
                  resolve(true)
                }
              })
            } else {
              reject()
            }
          })
        })
      }
    },

    affiliateAdd() {
      if (this.affiliateAdded) {
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.AffiliateDetailsRules.validate().then(async success => {
            if (success) {

              await this.$store.dispatch('affiliate/addAffiliate', this.affiliate_info).then(result => {

                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: result.code == 400 ? 'Error' : `Success`,
                    icon: result.code == 400 ? 'ThumbsDownIcon' : 'ThumbsUpIcon',
                    variant: result.code == 400 ? 'danger' : 'success',
                    text: result.message,
                  },
                })
                if (result.code === 200) {
                  console.log(result)
                  this.business_slug = result.data.business_slug
                  this.businessAdded = true
                  this.finished = true
                  resolve(true)

                } else {
                  reject()
                }

              })
            } else {
              reject()
            }
          })
        })
      }
    },

  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/pages/page-profile.scss';
</style>
